.modal {
    display: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: white;
    max-height: 100vh;
    overflow-y: auto;
}
  
.modal-open {
    display: block;
}

.modal-container {
    display: flex;
    flex-direction: column;
    padding: 50px;
    
}

.modal-close-container {
    width: 25px;
    height: 25px;
}
  
.modal-close-item {
    height: 25px;
    width: 2px;
    margin-left: 12px;
    background-color: #656565;
    transform: rotate(45deg);
    Z-index: 1;
}

.modal-close-element {
    height: 25px;
    width: 2px;
    background-color: #656565;
    transform: rotate(90deg);
    Z-index: 2;
}

.modal-separator {
    width: 100%;
    height: 1px;
    background-color: #656565;
    margin: 5px 0;
}

.filters-btn {
    width: 116px;
    height: 34px;
    background: #E4F1FA;
    border-radius: 5px;
    border: 1px solid #656565;
    font-size: 12px;
    font-weight: 500;
    margin-top: 15px;
    align-self: center;
}

.filter-bar {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 40px;
    justify-content: flex-end;
    gap: 15px;
}

.filter-btn {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: transparent;
    border: none;
}

.filter-text:hover {
    text-decoration: underline;
    text-decoration-color: #484848;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
}

.dropdown {
    display: none;
    position: absolute;
    background-color: rgba(255,255,255,1);
    min-width: 160px;
    padding: 30px 25px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 100%;
    right: 0;
    border-radius: 5px;
}

.dropdown-open {
    display: flex;
}