.services-container {
    background-color: #E4F1FA;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px; 
    margin: 25px 0;
}

.services-container-blob1 {
    position: absolute;
    top: 0;
    left: 0;
}

.services-container-blob2 {
    position: absolute;
    bottom: 0;
    right: 0;
}

.services-card {
    background-color: #C9E5F9;
    max-width: 290px;
    display: flex;
    align-items: center;
    z-index: 1;
    border-radius: 5px;
    padding: 12px 20px 12px 0;
    gap: 15px;
}

.services-card-title {
    margin: 0;
}

.services-card-info {
    display: flex;
    flex-direction: column;
}

.services-btn-whatsapp {
    background-color: #EEFFF0;
    color: #484848;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 120px;
    text-decoration: none;
    padding: 6px 0;
}

@media only screen and (min-width: 768px) {
    .services-card {
        max-width: 350px;
    }

    .services-btn-whatsapp {
        width: 100%;
        max-width: 180px;
        padding: 12px 0;
    }
}

@media only screen and (min-width: 1366px) {
    .services-container {
        flex-direction: row;
        gap: 35px;
    }

    .services-card {
        min-height: 195px;
    }

    .services-card-house {
        width: 20%;
    }
}