.contact-container {
    display: flex;
    flex-direction: column;
    margin: 20px 22px;
}

.contact-intro-text {
    white-space: pre-wrap;
}

.contact-container-features {
    flex-basis: 100%;
}

.contact-features {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contact-feature {
    display: flex;
    align-items: center;
    gap: 10px;
}

.contact-container-forms {
    flex-basis: 100%;
    background-color: #E4F1FA;
    border-radius: 5px;
    padding: 22px 25px;
    margin-top: 10px;
}

.contact-text {
    width: 200px;
    margin-bottom: 14px;
}

label {
    display: block;
}

input {
    background-color: transparent;
    border: 1px solid #656565;
    border-radius: 2px;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 14px;
}

input[type="text"],
input[type="tel"],
input[type="email"] {
    font-family: 'Karla', sans-serif;
    font-size: 10px;
    font-weight: 400;
}

input::placeholder {
    font-family: 'Karla', sans-serif;
    font-size: 10px;
    font-weight: 400;
}

textarea {
    background-color: transparent;
    border: 1px solid #656565;
    border-radius: 2px;
    font-family: 'Karla', sans-serif;
    font-size: 10px;
    font-weight: 400;
    width: 100%;
    margin-top: 4px;
    resize: none;
}

.forms-btn {
    display: flex;
    align-items: center;
    background-color: #FFF2E7;
    border: 1px solid #EFA25C;
    color: #1D1C1A;
    border-radius: 5px;
    padding: 11px 8px;
    gap: 10px;
    margin-top: 14px;
    margin-bottom: 14px;
    cursor: pointer;
}

.forms-btn-icon {
    width: 7px;
    height: 7px;
}

.forms-whatsapp-link {
    color: #579A37;
}

.forms-whatsapp-link:visited {
    color: #579A37; 
}

.contact-forms-social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 16px;
}

@media only screen and (min-width: 768px) {
    .contact-container { 
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        gap: 65px;
        margin: 75px 170px 26px 170px;
    }
    .contact-container-features {
        max-width: 560px;
    }
    .contact-container-forms { 
        margin-top: 0;
        max-width: 560px;
    }
    .contact-text {
        width: 270px;
        margin-bottom: 21px;
    }
    input { 
        margin-top: 8px;
        margin-bottom: 25px; 
    }
    input[type="text"],
    input[type="tel"],
    input[type="email"] { font-size: 14px; }
    input::placeholder { font-size: 14px; }
    textarea { 
        font-size: 14px;
        margin-top: 8px;
    }

    .forms-btn {
        padding: 18px 23px;
        gap: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .forms-btn-icon {
        width: 10px;
        height: 10px;
    }
}
