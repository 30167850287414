@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500;700&display=swap');


html {
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1D1C1A;
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.h1-title-large {
  font-family: Helvetica;
  font-size: 32px;
  font-weight: 700;
}

.h2-title-large {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 700;
}

.h3-title-medium {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 700;
}

.h4-title-small {
  font-family: 'Karla', sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.h5-title-small {
  font-family: 'Karla', sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.body-text-large {
  font-family: 'Karla', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.body-text-medium {
  font-family: 'Karla', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.body-text-small {
  font-family: 'Karla', sans-serif;
  font-size: 10px;
  font-weight: 400;
}

.btn {
  font-family: 'Karla', sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.icon-regular {
  width: 16px;
  height: 16px;
}

.margin-auto {
  margin: auto;
}

.text-align-center {
  text-align: center;
}

.color-black {
  color: #000000;
}

.color-gray {
  color: #484848;
}

.color-orange-hover {
  color: #EFA25C;
}

@media only screen and (min-width: 768px) {
  .h1-title-large { font-size: 64px; }
  .h2-title-large { font-size: 54px; }
  .h3-title-medium { font-size: 42px; }

  .h4-title-small {
    font-size: 24px;
    font-weight: 700;
  }

  .h5-title-small {
    font-size: 14px;
    font-weight: 700;
  }

  .body-text-large { font-size: 24px; }
  .body-text-medium { font-size: 16px; }
  .body-text-small { font-size: 14px; }
  .btn { font-size: 18px; }

  .icon-regular {
    width: 22px;
    height: 22px;
  }
}
