.banner-container {
    position: relative;
    height: calc(100vh - 125px);
    width: 100%;
    background: white;
}

.banner {
    height: calc(100vh - 125px);
    width: 100%;
    object-fit: cover;
    object-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.banner::before {
    content: '';
    display: block;
    height: 100vh;
}

.banner-loaded {
    opacity: 1;
}

.banner-filter {
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 1), 30%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

.banner-logo {
    align-self: flex-start;
    z-index: 1
}

.banner-text {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    white-space: pre-wrap;
    font-size: 24px;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.banner-text-loaded {
    opacity: 1;
}