.notfound-container {
    min-height: calc(100vh - 125px - 63px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notfound-text {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
}

.notfound-btn {
    background-color: #FFF2E7;
    width: 200px;
    height: 58px;
    border: 1px solid #EFA25C;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    text-decoration: none;
    color: #1D1C1A;
}