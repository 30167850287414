.legal-container {
    min-height: calc(100vh - 125px - 63px);
    margin: 0 20px;
}

.legal-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 11px;
}

.legal-navbar-btn {
    background: none;
    border: none;
    cursor: pointer;
    width: min-content;
    color: #1D1C1A;
}

.legal-navbar-btn-underline {
    margin-top: 4px;
    width: 100%;
    height: 1px;
    background: #1D1C1A;
}

.legal-text-container {
    margin: 20px auto;
}

.legal-text {
    margin: 20px auto;
    padding: auto 16px;
    white-space: pre-wrap;
}

.legal-text-link-container {
    margin: 8px 0;
}

.legal-text-link {
    color: #1D1C1A;
    text-decoration: underline;
    text-decoration-color: #1D1C1A;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
}

.legal-table {
    border-collapse: collapse;
    margin: 20px auto;
}

.legal-table td, th {
    border: 1px solid gray;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .legal-navbar { gap: 25px }

    .legal-navbar-btn-underline {
        margin-top: 8px;
        height: 4px;
    }

    .legal-text { max-width: 800px }
    .legal-text-link { text-decoration-thickness: 2px }
}

@media only screen and (min-width: 1366px) {
    .legal-navbar-btn { white-space: nowrap }
    .legal-text-container { margin: 72px auto }
    .legal-text { max-width: 1100px; margin: 30px auto }
    .legal-table { margin: 30px auto }
}