.footer-container {
    border-top: 1px solid #656565;
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-link {
    text-decoration: none;
    color: #1D1C1A;
}

@media only screen and (min-width: 768px) {
    .footer-container {
        margin: 0 50px;
    }
}