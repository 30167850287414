.navbar-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    background: white;
}

.navbar {
    display: flex;
    flex-direction: column;
    padding: 0 0 8px 0;
}

.navbar-items-container {
    display: flex;
    justify-content: space-around;
    padding: 15px 0;
}

.navbar-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #1D1C1A;
    text-decoration: none;
}

.navbar-links-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
}

.navbar-icons {
    display: flex;
    align-items: center;
}

.navbar-icons-logo {
    height: 47px;
}

.navbar-links {
    display: flex;
    gap: 15px;
}

.navlink {
    font-family: 'Karla', sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
    color: #656565;
}

.navlink-active {
    color: #D16612;
    font-weight: 500;
}

.open-filters {
    width: 0;
    opacity: 0;
    height: 16px;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    transition: width 0.5s, opacity 0.8s;
}

.open-filters-active {
    width: 28px;
    opacity: 1;
}

.open-filters-icon {
    width: 100%;
    height: 2px;
    background: #656565;
    border-radius: 5px;
    margin: 3.5px 0;
}

@media only screen and (min-width: 768px) {
    .navbar-container {
        display: flex;
        flex-direction: column;
    }

    .navbar {
        flex-direction: row;
        justify-content: space-around;
        padding: 10px 0;
    }

    .navbar-icons-logo { margin: 0 15px; }

    .navbar-items-container {
        order: 2;
        gap: 15px;
    }

    .navbar-links { gap: 30px; }

    .navlink {
       font-size: 16px;
       font-weight: 500;
    }

    .navlink-active {
        text-underline-offset: 5px;
        font-weight: 700;
    }

    .navbar-filter-bar {
        opacity: 0;
        transition: opacity 1s;
    }

    .navbar-filter-bar-active {
        opacity: 1;
    }
}