.property-card {
    display: flex;
    justify-content: center;
}

.property-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1310px;
    margin: 25px 30px;
    padding: 25px 20px;
    position: relative;
    background-color: #FFF5F0;
    border-radius: 5px;
    position: relative;
}

.property-card-photo-container {
    width: 100%;
    opacity: 1;
    transition: opacity 0.2s;
    position: relative;
}

.property-card-photo-container:hover {
    opacity: 0.6;
}

.property-card-photo {
    width: 100%;
}

.property-card-photo-text {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    opacity: 1;
    background: rgba(255, 255, 255, 0.5);
    color: black;
    padding: 6px 4px;
}

.googlemaps-link {
    color: #484848;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 200px;
    margin-top: 15px;
}

.googlemaps-iframe {
    height: 144px;
    width: 100%;
    border: none;
    border-radius: 4px;
}

.property-card-title {
    margin-top: 15px;
}

.property-description {
    white-space: pre-wrap;
}

.property-description-seemore-btn {
    background: none;
    border: none;
    color: #1777F2;
    text-decoration: underline;
    padding: 0;
    cursor: pointer;
}

.property-card-general-info {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.property-card-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price-tag-container {
    background-color: #FFD8BC;
    color: #1D1C1A;
    width: 130px;
    height: 36px;
    border: none;
    border-radius: 5px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    gap: 10px;
}

.prices {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.prices div {
    align-self: flex-start;
    display: flex;
    justify-content: space-between;
}

.is-discount {
    align-self: flex-start;
    text-decoration: line-through;
    font-size: 9px;
}

.whatsapp-btn {
    background-color: #EEFFF0;
    width: 111px;
    height: 36px;
    border: none;
    border-radius: 5px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: #484848;
}

.property-card-info-content {
    width: 100%;
}

.property-card-info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    border-top: 1px solid #656565;
    border-bottom: 1px solid #656565;
    margin: 15px 0;
}

.info-item {
    display: flex;
    align-items:center;
    gap: 6px; 
}

.info-item-separator {
    display: none;
}

.property-card-features {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 5px;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 6px;
}

.modal-gallery {
    position: absolute;
    top: 50%;
    left: 50%;
    transform : translate(-50%, -50%);
    width: 80vw;
    background-color: white;
    border: none;
    border-radius: 6px;
    padding: 10px;
}

.modal-gallery-content {
    position: relative;
}

.modal-card-photo {
    width: 100%;
}

.modal-gallery-buttons {
    background: none;
    border: none;
}

.modal-gallery-button {
    border: solid rgba(11, 11, 10, 0.4);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 15px;
}

.modal-gallery-button-left {
    transform: rotate(135deg);
    position: absolute;
    top: 50%;
    left: 25px;
}

.modal-gallery-button-right {
    transform: rotate(-45deg);
    position: absolute;
    top: 50%;
    right: 25px;
}

.modal-gallery-close-container {
    margin-left: auto;
    margin-right: 12px;
    width: 25px;
    height: 25px;
}
  
.modal-gallery-close-item {
    height: 25px;
    width: 2px;
    margin-left: 12px;
    background-color: #656565;
    transform: rotate(45deg);
    Z-index: 1;
}

.modal-gallery-close-element {
    height: 25px;
    width: 2px;
    background-color: #656565;
    transform: rotate(90deg);
    Z-index: 2;
}

.property-features-seemore-container {
    background: #FFF2E7;
    height: 28px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.property-features-seemore-btn {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9px;
    color: #484848;
    cursor: pointer;
}

.property-features-seemore-icon {
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;
}

.property-features-seemore-icon-active {
    transform: rotate(180deg);
}

.icon-ribbon {
    position: absolute;
    top: 0;
    right: 0;
    width: 64px;
}

@media only screen and (min-width: 768px) {
    .property-card-container {
        flex-direction: row;
        align-items: flex-start;
        gap: 34px;
        margin: 45px 30px;
    }

    .property-card-media-content {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .icon-ribbon {
        top: -25px;
    }

    .property-card-title {
        margin-top: 0;
    }

    .property-card-general-info {
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid #656565;
        border-bottom: 1px solid #656565;
        padding: 15px 0;
    }

    .property-card-info {
        padding: 0 25px;
        border: none;
        border-left: 1px solid #656565;
        margin: 0;
    }

    .info-item-separator {
        display: block;
        height: 100%;
        width: 1px;
        background: #656565;
    }

    .property-card-buttons {
        flex-wrap: wrap;
        gap: 15px;
    }

    .price-tag-container {
        width: 154px;
        height: 57px;
        padding: 0 23px;
    }

    .whatsapp-btn {
        width: 154px;
        height: 57px;
        padding: 0 23px;
        gap: 15px;
    }

    .property-card-features {
        grid-template-columns: 33% 33% 33%;
        padding: 0 15px;
    }

    .modal-gallery {
        width: 50vw;
    }
}

@media only screen and (min-width: 1366px) {
    .modal-gallery {
        width: 40vw;
        max-width: 1000px;
    }
}

